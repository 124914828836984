import React from 'react';
import { kebabCase } from 'lodash';
import { Helmet } from 'react-helmet-async';
import { Link, graphql } from 'gatsby';
import Layout from '../../components/Layout';
import './style.sass';

const defaultBlessings = [
  '縁切り',
  '縁結び',
  '家内安全',
  '仕事・金運',
  '厄除け',
];

export default ({
  data: {
    allMarkdownRemark: { group },
  },
}) => {
  return (
    <Layout>
      <Helmet titleTemplate="%s | その他のご利益">
        <title>その他のご利益</title>
        <meta
          name="description"
          content="神社／パワースポット検索サイト・ヒナタビで検索できる神社のご利益一覧です"
        />
      </Helmet>

      <ul className="blessings">
        {group.map(blessing => {
          if (defaultBlessings.includes(blessing.fieldValue)) {
            return null;
          }

          return (
            <li className="blessings__item" key={blessing.fieldValue}>
              <Link to={`/blessings/${kebabCase(blessing.fieldValue)}/`}>
                {blessing.fieldValue} ({blessing.totalCount})
              </Link>
            </li>
          );
        })}
      </ul>
    </Layout>
  );
};

export const query = graphql`
  query blessings {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(limit: 1000) {
      group(field: frontmatter___blessings) {
        fieldValue
        totalCount
      }
    }
  }
`;
